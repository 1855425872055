.footer {
    background-color: black;
    margin-bottom: 0;
    padding:1rem;
}

.footer-text {
    color: white;
    margin-bottom: 0;
    font-family: 'Marcellus', serif;
    font-size: 14px;
}