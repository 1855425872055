.Navbar {
    background-color: black;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width:100vw;

  }

.Nav {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    padding: 10px;
}

.NavBrand {

    font-family: 'Playfair Display SC', serif;
}

.NavLink {
    /* background-color: blue; */
    color: white;
    text-decoration: none;
    font-family: 'Playfair Display SC', serif;
}

.SelectedNavLink {
    color: purple;
    text-decoration: none;
    font-family: 'Playfair Display SC', serif;
}