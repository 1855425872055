

.title {
    font-family: 'Playfair Display SC';
    font-style:italic;

}

.subtitle {
    font-family: 'Marcellus', serif;
    font-size: 48px;
}

.section {
    padding:"10vw"
}

.social-link {
    width: 100%;
    padding: 10px 0;
}
