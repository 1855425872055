.modal {
    color: black;
}

.modal-body {
    background-color: black !important;
    text-align: center;
}

.notify {
    color: #C8B16F;
}
.link {
    color:white;
}
h2 {
    font-family: 'Marcellus', serif;
    font-size: 24px;
    color: white;
}

h5 {
    font-family: 'Marcellus', serif;
    font-size: 14px;
    color: #9B9B9B;
}
p {
    font-family: 'Marcellus', serif;
    font-size: 12px;
    color: #9B9B9B;
}

.icon {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.cover-photo {
    max-width: 80vw;
}

a {
    text-decoration: none !important;

}